import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'

import aggregate from '../images/draws/email.svg'
import charts from '../images/draws/data-trends.svg'
import news from '../images/draws/news.svg'
import contact from '../images/draws/contact.svg'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Media`,
        `News`,
        `Fake News`,
        `Natural Language Processing`,
        `NLP`,
      ]}
    />
    <div className="page white-bg">
      <div className="container">
        <section className="hero-section">
          <div className="hero-texts">
            <p className="biasimpact-header">
              Data and bias analysis for every news story.
              <br />
            </p>
            <p>
              BiasImpact uses natural language processing to score the article
              you’re reading so you can see your news in a new light.
            </p>
            <a
              href="https://bit.ly/DataForNewsDiscord"
              className="btn-hero-purple"
            >
              Join Us on Discord!
            </a>
          </div>
          <div>
            <img src={news} className="hero-image-first" />
          </div>
        </section>
      </div>
      <div className="container">
        <section className="hero-section2 ">
          <img src={aggregate} className="hero-image" />
          <div className="hero-texts">
            <p className="hero-header">
              Hundreds of sources, millions of articles
            </p>
            <div className="dash" />
            <p>
              We scrape news sources around the world to identify bias on any
              topic.
            </p>
          </div>
        </section>
      </div>

      <div className="container">
        <section className="hero-section3">
          <div className="hero-texts">
            <p className="hero-header">High tech made low tech</p>
            <div className="dash" />
            <p>
              Our algorithms are complicated, but our user interface is simple
              enough for anyone to use.
            </p>
          </div>
          <img src={charts} className="hero-image" />
        </section>
      </div>
      <div className="container" id="contact">
        <section className="hero-section4">
          <div>
            <img src={contact} className="hero-image" />
          </div>
          <div className="hero-texts">
            <p className="hero-header">Sign up for our newsletter !</p>
            <div className="dash" />
            <p>
              To stay in touch with us and updated for the coming changes, leave
              us your contact.
            </p>
            <form
              action="https://formspree.io/contact@biasimpact.org"
              method="POST"
            >
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  required
                />
                <div className="input-group-append">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export default IndexPage
